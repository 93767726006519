import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

const CurrentCongress = () => {
  const [houseData, setHouseData] = useState([]);
  const [senateData, setSenateData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiKey = "NHdJjzbcGyGWRWiqihZ5SH6FZdChkW2fMVOBh5lT";

  const processData = useCallback((data) => {
    if (!data || !data.members || !Array.isArray(data.members)) {
      console.error("Invalid data structure:", data);
      return [];
    }
    
    console.log("Processing members:", data.members.length);
    
    const partyCount = data.members.reduce((acc, member) => {
      const party = member.partyName || (member.terms && member.terms.partyName) || "Unknown";
      acc[party] = (acc[party] || 0) + 1;
      return acc;
    }, {});

    console.log("Party count:", partyCount);

    return Object.entries(partyCount).map(([name, value]) => ({ name, value }));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const congressResponse = await axios.get(`https://api.congress.gov/v3/congress/current?api_key=${apiKey}`);
        console.log("Congress Response:", congressResponse.data);
        
        if (!congressResponse.data.congress) {
          throw new Error("No congress data available");
        }
        
        const currentCongress = congressResponse.data.congress.number;
        console.log("Current Congress:", currentCongress);

        try {
          const membersResponse = await axios.get(`https://api.congress.gov/v3/member?api_key=${apiKey}&congress=${currentCongress}`);
          console.log("Members Response:", membersResponse.data);

          const members = membersResponse.data.members;
          const houseMembers = members.filter(member => member.chamber === "House");
          const senateMembers = members.filter(member => member.chamber === "Senate");

          setHouseData(processData({ members: houseMembers }));
          setSenateData(processData({ members: senateMembers }));
        } catch (error) {
          console.error("Error fetching members data:", error.response ? error.response.data : error.message);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching congress data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [processData]);

  const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <h1>Current Congress Composition</h1>
      <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
        <div style={{ width: '45%', minWidth: '300px' }}>
          <h2>House of Representatives</h2>
          {houseData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={houseData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {houseData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <p>No data available for House of Representatives</p>
          )}
        </div>
        <div style={{ width: '45%', minWidth: '300px' }}>
          <h2>Senate</h2>
          {senateData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={senateData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {senateData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <p>No data available for Senate</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrentCongress;