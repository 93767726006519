import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Members from "./Members";
import MemberDetail from "./MemberDetail";
import Bills from "./Bills";
import BillDetail from "./BillDetail";
import { LoadingProvider } from "./LoadingContext";
import Header from "./Header"; // Import the header component
import EmailBox from "./EmailBox";
import "./App.css";
import CurrentCongress from "./CurrentCongress";
import Laws from "./Laws";
import "./Laws.css";  

const App = () => {
  const apiKey = "NHdJjzbcGyGWRWiqihZ5SH6FZdChkW2fMVOBh5lT";

  return (
    <LoadingProvider>
      <Router>
        <div className="app-container">
          <Header /> {/* Include the header here */}
          <div className="content">
            <Routes>
              <Route path="/" element={<Bills apiKey={apiKey} />} />
              <Route
                path="/bills/:congress/:type/:number"
                element={<BillDetail apiKey={apiKey} />}
              />
              <Route path="/members" element={<Members apiKey={apiKey} />} />
              <Route
                path="/members/:memberId"
                element={<MemberDetail apiKey={apiKey} />}
              />
              <Route path="/email" element={<EmailBox />} />
              <Route path="/bill/:congress/:type/:number" element={<BillDetail />} />
              <Route path="/member/:bioguideId" element={<MemberDetail />} />
              <Route path="/current-congress" element={<CurrentCongress />} />
              <Route path="/laws/:congress" element={<Laws />} />
            </Routes>
          </div>
        </div>
      </Router>
    </LoadingProvider>
  );
};

export default App;
