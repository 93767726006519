import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './Laws.css';

const Laws = () => {
  const [laws, setLaws] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { congress } = useParams();
  const navigate = useNavigate();
  const apiKey = 'NHdJjzbcGyGWRWiqihZ5SH6FZdChkW2fMVOBh5lT'; // Your actual API key

  useEffect(() => {
    const fetchLaws = async () => {
      try {
        const response = await axios.get(`https://api.congress.gov/v3/law/${congress}`, {
          params: {
            api_key: apiKey,
            limit: 250 // Adjust as needed
          }
        });
        setLaws(response.data.bills);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching laws:', error);
        setError('Failed to fetch laws. Please try again later.');
        setLoading(false);
      }
    };

    fetchLaws();
  }, [congress, apiKey]);

  const handleLawClick = (law) => {
    navigate(`/bill/${congress}/${law.type}/${law.number}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="laws-container">
      <br />
      <br />
      <br />
      <ul className="laws-list">
        {laws.map((law, index) => (
          <li 
            key={index} 
            className="law-item" 
            onClick={() => handleLawClick(law)}
          >
            <h2>{law.title}</h2>
            <p>Bill: {law.type} {law.number}</p>
            <p>Origin: {law.originChamber}</p>
            <p>Latest Action: {law.latestAction.text} ({law.latestAction.actionDate})</p>
            {law.laws && law.laws.length > 0 && (
              <p>Public Law: {law.laws[0].type} {law.laws[0].number}</p>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Laws;